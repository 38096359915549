<template>
  <div id="evento-noticia">
    <v-container grid-list-xl fluid>
      <header-title title="Eventos y Noticias"></header-title>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn type="button" color="primary" dark @click="showDialog()">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="eventos"
        :loading="loading"
        :search="search"
        calculate-widths
        no-data-text="No existen registros"
        no-results-text="Sin resultados"
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <template v-slot:item.fecha="{ item }">
          <td>
            {{ item.fecha | formatdate }}
          </td>
        </template>
        <!-- <template v-slot:item.imagen="{ item }">
          <td>
            <a
              :href="serve + '/eventos/' + item.imagen"
              target="_blank"
              class="link-docs"
            >
              Enlace del archivo
            </a>
          </td>
        </template> -->
        <template v-slot:item.estado="{ item }">
          <td>
            <span
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
            >
              <v-chip small color="green lighten-4">activo</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">inactivo</v-chip>
            </span>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  type="button"
                  icon
                  color="blue"
                  @click="editImage(item.id, item.imagen)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-picture-in-picture-bottom-right</v-icon>
                </v-btn>
              </template>
              <span>Imagen</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  type="button"
                  icon
                  color="success"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  color="red"
                  @click="confirmItem(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="mdialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Evento y Noticia</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-flex xs12>
                <v-text-field
                  label="Título *"
                  v-model="evento.titulo"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  autocomplete="off"
                  required
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  type="text"
                  label="Descripción *"
                  rows="3"
                  v-model="evento.descripcion"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  autocomplete="off"
                  :counter="300"
                  required
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Fecha *"
                      append-icon="mdi-calendar"
                      :rules="[(v) => !!v || 'El campo es requerido']"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="evento.fecha"
                    @input="menu = false"
                    locale="es"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-layout wrap>
                <v-flex xs12 v-if="mode">
                  <v-file-input
                    type="file"
                    v-model="evento.imagen"
                    accept="image/png, image/jpeg"
                    label="Imagen *"
                    @change="onImageChange($event)"
                    :rules="[(v) => !!v || 'La imagen es requerido']"
                    required
                  ></v-file-input>
                </v-flex>
                <v-flex sm6 xs12>
                  <v-select
                    label="Tipo *"
                    :items="tipo_noticias"
                    v-model="evento.tipo_noticia_id"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[(v) => !!v || 'Seleccione una opción']"
                  ></v-select>
                </v-flex>
                <v-flex sm6 xs12>
                  <v-select
                    label="Estado"
                    :items="states"
                    v-model="evento.estado"
                    item-text="descripcion"
                    item-value="id"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="mode"
              @click.native="createEvento()"
              >Guardar</v-btn
            >
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="!mode"
              @click.native="updateEvento()"
              >Actualizar</v-btn
            >
            <v-btn color="red" text @click.native="mdialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="idialog" scrollable max-width="900px">
      <v-form ref="iform">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Imagen de la noticia</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <!-- <v-flex xs12>
                <v-text-field
                  label="Título *"
                  v-model="evento.titulo"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  required
                >
                </v-text-field>
              </v-flex> -->
              <v-flex xs12>
                <img
                  v-if="evento.imagen"
                  :src="`${serve}/noticias/${evento.imagen}`"
                  width="100%"
                  alt="Imagen de la noticia"
                />
              </v-flex>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="mode"
              @click.native="createEvento()"
              >Guardar</v-btn
            >
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="!mode"
              @click.native="updateEvento()"
              >Actualizar</v-btn
            > -->
            <v-btn color="red" text @click.native="idialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="mconfirm" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de eliminar el registro?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="btn_loading"
            text
            @click="deleteItem()"
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="mconfirm = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
    >
      {{ snack.text }}
      <!-- <v-btn dark text @click="snack.state = false">Cerrar</v-btn> -->
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "evento-noticia",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      search: "",
      menu: false,
      loading: false,
      btn_loading: false,
      headers: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Título ", value: "titulo" },
        { text: "Descripción ", value: "descripcion", width: "50%" },
        { text: "fecha ", value: "fecha" },
        { text: "Estado ", value: "estado", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      tipo_noticias: [],
      eventos: [],
      evento: {
        id: "",
        titulo: "",
        descripcion: "",
        tipo_noticia_id: "",
        imagen: null,
        fecha: "",
        estado: "",
      },
      serve: "",
      states: [],
      mdialog: false,
      idialog: false,
      mconfirm: false,
      mode: true,
      snack: {
        state: false,
        color: "success",
        text: "",
      },
    };
  },
  created() {
    this.serve = Service.getServe();
  },
  computed: {
    dateFormatted() {
      if (!this.evento.fecha) return null;

      const [year, month, day] = this.evento.fecha.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    if (Service.getUser()) {
      if (Service.getUser().role == 1) {
        this.states = Service.getStates();
        this.getEventoNoticias();
        this.getTipoEventoNoticia();
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
  },
  methods: {
    onImageChange(event) {
      this.evento.imagen = event;
    },
    getEventoNoticias() {
      this.loading = true;
      axios
        .get(Service.getBase() + "noticia", Service.getHeader())
        .then((response) => {
          this.loading = false;
          this.eventos = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    getTipoEventoNoticia() {
      axios
        .get(Service.getBase() + "tiponoticia/activo", Service.getHeader())
        .then((response) => {
          this.tipo_noticias = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    showDialog() {
      this.evento = {
        id: "",
        titulo: "",
        descripcion: "",
        tipo_noticia_id: "",
        imagen: null,
        fecha: "",
        estado: true,
      };
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.btn_loading = false;
      this.mode = true;
      this.mdialog = true;
    },

    createEvento() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        let fdata = new FormData();
        fdata.append("titulo", this.evento.titulo);
        fdata.append("descripcion", this.evento.descripcion);
        fdata.append("tipo_noticia_id", this.evento.tipo_noticia_id);
        fdata.append("imagen", this.evento.imagen);
        fdata.append("fecha", this.evento.fecha);
        fdata.append("estado", this.evento.estado);
        axios
          .post(Service.getBase() + "noticia", fdata, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: Service.getToken(),
            },
          })
          .then(() => {
            this.btn_loading = false;
            this.toast("success", "Registro guardado");
            this.mdialog = false;
            this.getEventoNoticias();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast("error", "No se pudo guardar");
          });
      }
    },

    editItem(data) {
      this.evento = data;
      this.mode = false;
      this.mdialog = true;
    },

    updateEvento() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        axios
          .put(
            Service.getBase() + "noticia/" + this.evento.id,
            {
              descripcion: this.evento.descripcion,
              estado: this.evento.estado,
            },
            Service.getHeader()
          )
          .then((response) => {
            this.btn_loading = false;
            this.toast("success", response.data.message);
            this.mdialog = false;
            this.getEventoNoticias();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast("error", "No se pudo actualizar");
          });
      }
    },

    editImage(noticia_id, imagen) {
      this.evento.id = noticia_id;
      this.evento.imagen = imagen;
      this.mode = false;
      this.idialog = true;
    },

    confirmItem(id) {
      this.evento.id = id;
      this.mconfirm = true;
    },

    deleteItem() {
      this.btn_loading = true;
      axios
        .delete(
          Service.getBase() + "noticia/" + this.evento.id,
          Service.getHeader()
        )
        .then(() => {
          this.btn_loading = false;
          this.toast("success", "Registro eliminado");
          this.mconfirm = false;
          this.getEventoNoticias();
        })
        .catch(() => {
          this.btn_loading = false;
          this.toast("error", "Registro en uso o no existente");
        });
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
